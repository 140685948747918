import "core-js/modules/es.array.index-of";import "core-js/modules/es.object.to-string"; /*
                                                                                          * @Description:
                                                                                          * @Version: 1.0
                                                                                          * @Autor: silencc
                                                                                          * @Date: 2020-10-13 09:13:03
                                                                                          * @LastEditors: silencc
                                                                                          * @LastEditTime: 2020-10-20 13:27:34
                                                                                          */
import originJsonp from 'jsonp';
import {
param } from
'./help';

/**
           *
           * @param {*} url
           * @param {*} data
           * @param {Object： 可选} option
              值： param (String) 用于指定回调的查询字符串参数的名称 (默认为 callback)
                  timeout (Number) 超时错误发出后多长时间. 0 to disable (defaults to 60000)
                  prefix (String) 处理jsonp响应的全局回调函数的前缀 (defaults to __jp)
                  name (String) 处理jsonp响应的全局回调函数的名称 (defaults to prefix + incremented counter)
           */
export function jsonp(url, data) {var option = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  url += (url.indexOf('?') < 0 ? '?' : '&') + param(data);
  return new Promise(function (resolve) {
    originJsonp(url, option, function (err, res) {
      resolve([err, res]);
    });
  });
}
/*
    api 示例：
    export function getData() {
      const url = 'http://xxxxx' //请求地址
      const data = {} //请求参数
      return jsonp(url, data, options) //
    }
    使用 示例：
    getData().then((res) => {
      if (res.code === ERR_OK) {
      }
    })
  */